import ApiService from "@/core/services/ApiService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { OrganismosListado } from "../models";

interface Organismo {
  id: string;
  nombre: string;
  tipoOrganizacionId: string;
  tipoOrganizacion: {
    id: string;
    nombre: string;
  };
  esUbicacionGeografica: boolean;
  telefono: string;
  direccion: string;
  email: string;
  emailProfesionales: string;
}

interface TipoOrganizacion {
  id: string;
  nombre: string;
}

interface OrganismoCrear {
  nombre: string;
  email: string;
  emailProfesionales: string;
  telefono: string;
  direccion: string;
  esUbicacionGeografica: boolean;
  tipoOrganizacionId: string;
}

@Module({ namespaced: true, name: "OrganismosModule" })
export class OrganismosModule extends VuexModule {
  organismosListado: OrganismosListado[] = [];
  organismosCompletosListado: OrganismosListado[] = [];
  organismo: OrganismosListado | null = null;
  tiposOrganizacionListado: TipoOrganizacion[] = [];
  organismoCrear: OrganismoCrear | null = null;

  @Action({ rawError: true })
  async fetchAll() {
    const url = `/comarcas`;
    const params = {
      filter: {
        include: [{ relation: "tipoOrganizacion" }],
        where: { esUbicacionGeografica: true },
      },
    };

    const { data } = await ApiService.get(url, { params });
    const comarcasOrdenadas = data
      .sort((a, b) => {
        // Ordenar primero por `tipoOrganizacionId` (personalizado) y luego por `nombre`
        const prioridad: { [key: string]: number } = {
          "4e962fb5-ebf5-482c-b794-1fe5d9111868": 1, // Comarca
          "5bd353f2-9d74-4a8f-9b1c-b70c8ccae440": 2, // Ayuntamiento
        };
        const prioridadA = prioridad[a.tipoOrganizacionId] || 999;
        const prioridadB = prioridad[b.tipoOrganizacionId] || 999;

        if (prioridadA !== prioridadB) {
          return prioridadA - prioridadB;
        }

        // Ordenar alfabéticamente por `nombre`
        return a.nombre.localeCompare(b.nombre);
      })
      .map((obj) => {
        // Añadir prefijos según el `tipoOrganizacionId`
        let nombreCompuesto = obj.nombre;
        if (obj.tipoOrganizacionId === "4e962fb5-ebf5-482c-b794-1fe5d9111868") {
          nombreCompuesto = `Comarca ${obj.nombre}`;
        } else if (
          obj.tipoOrganizacionId === "5bd353f2-9d74-4a8f-9b1c-b70c8ccae440"
        ) {
          nombreCompuesto = `Ayuntamiento ${obj.nombre}`;
        }
        return {
          ...obj,
          nombreCompuesto: nombreCompuesto,
        };
      });

    this.setListData(comarcasOrdenadas);
  }

  @Action({ rawError: true })
  async fetchAllConUbicaciones() {
    const url = `/comarcas`;
    const params = {
      filter: {
        include: [{ relation: "tipoOrganizacion" }],
      },
    };

    const { data } = await ApiService.get(url, { params });

    const prioridad = {
      "4e962fb5-ebf5-482c-b794-1fe5d9111868": 1, // Comarca
      "5bd353f2-9d74-4a8f-9b1c-b70c8ccae440": 2, // Ayuntamiento
      "ac604540-4f1c-41cd-8e6b-354b2cdbbbdb": 3, // Centro educativo público
      "bc604540-4f1c-41cd-8e6b-354b2cdbbbdb": 4, // Centro educativo privado
      "9e962fb5-ebf5-482c-b794-1fe5d9111868": 5, // Empresa Pública
      "fa535c00-9846-4319-8614-6c52c32fdbb4": 6, // Empresa Privada
      "939a138e-a8c5-44cb-b954-32b6586abbf0": 7, // Entidad sin Ánimo de Lucro
      "cc604540-4f1c-41cd-8e6b-354b2cdbbbdb": 8, // ONG
    };

    const comarcasOrdenadas = data
      .sort((a, b) => {
        // Obtener prioridad de cada elemento o asignar 999 si no está definido
        const prioridadA = prioridad[a.tipoOrganizacionId] ?? 999;
        const prioridadB = prioridad[b.tipoOrganizacionId] ?? 999;

        // Comparar prioridad primero
        if (prioridadA !== prioridadB) {
          return prioridadA - prioridadB;
        }

        // Si tienen la misma prioridad, ordenar alfabéticamente
        return a.nombre.localeCompare(b.nombre, "es", { sensitivity: "base" });
      })
      .map((obj) => {
        // Añadir prefijos según el `tipoOrganizacionId`
        let nombreCompuesto = obj.nombre;
        if (obj.tipoOrganizacionId === "4e962fb5-ebf5-482c-b794-1fe5d9111868") {
          nombreCompuesto = `Comarca ${obj.nombre}`;
        } else if (
          obj.tipoOrganizacionId === "5bd353f2-9d74-4a8f-9b1c-b70c8ccae440"
        ) {
          nombreCompuesto = `Ayuntamiento ${obj.nombre}`;
        }
        return {
          ...obj,
          nombreCompuesto: nombreCompuesto,
        };
      });

    this.setListCompleteData(comarcasOrdenadas);
  }

  @Action({ rawError: true })
  async fetchById(id: string) {
    const { data } = await ApiService.get(`/comarcas/${id}`, {
      params: {
        filter: {
          include: [{ relation: "tipoOrganizacion" }],
        },
      },
    });
    this.setData(data);
  }

  @Action({ rawError: true })
  async fetchTipoOrganizaciones() {
    const { data } = await ApiService.get("/tipos-organizacion");
    this.setListTipoOrganizaciones(data);
  }

  @Action({ rawError: true })
  async crear(organismoCrear: Partial<OrganismoCrear>) {
    return await ApiService.post(`/comarcas`, organismoCrear).catch(
      ApiService.handleError
    );
  }

  @Action({ rawError: true })
  updateById({ id, organismo }: { id: string; organismo: Partial<Organismo> }) {
    return ApiService.put(`/comarcas/${id}`, organismo);
  }

  @Action({ rawError: true })
  deleteById(id: string) {
    return ApiService.delete(`/comarcas/${id}`);
  }

  @Mutation
  setListData(data) {
    this.organismosListado = data;
  }

  @Mutation
  setListCompleteData(data) {
    this.organismosCompletosListado = data;
  }

  @Mutation
  setListTipoOrganizaciones(data) {
    this.tiposOrganizacionListado = data;
  }

  @Mutation
  setData(data) {
    this.organismo = data;
  }
}
